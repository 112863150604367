import { Link } from "gatsby";
import React from "react"
import Layout from "../../../components/Layout"
import Status from "../../../components/Status"
import WrapperView from "../../../components/WrapperView";

const hljs = require('highlight.js/lib/common');

const code1 = hljs.highlight(`
npm init
`, {language: 'javascript'}).value

const code2 = hljs.highlight(`
npm install express
`, {language: 'javascript'}).value

const code3 = hljs.highlight(`
const https = require('node:https');
const fs = require('node:fs');

// Import the express module
const express = require("express");

// Instantiate an Express application
const app = express();

https
.createServer(
    // Provide the private key and certificate to the server by reading each
    // file's content with the readFileSync() method.
    {
    key: fs.readFileSync("key.pem"),
    cert: fs.readFileSync("cert.pem"),
    },
    app
)
.listen(8001, () => {
    console.log("Server is runing at port 8001");
});

app.get('/', (req,res)=>{
    res.send("Hello from express server.")
})
`, {language: 'javascript'}).value

const code4 = hljs.highlight(`
node index.js
`, {language: 'javascript'}).value

const FaqPage = (props) => (
    <Layout  {...props}>
    <Status auth={props.auth}  user={props.user}  />
    <WrapperView title="How to configure SSL certificate with Express.JS for an HTTPS server">
    <div>
        1. Initialize your NodeJS project and generate the package.json file.
    </div>
    <div className="mockup-code text-sm">
        <pre style={{'paddingLeft': '18px'}}>
        <code className="language-javascript" dangerouslySetInnerHTML={{ __html: code1 }}>
        </code>
        </pre>
    </div>
    <div>
        2. Install Express.JS
    </div>
    <div className="mockup-code text-sm">
        <pre style={{'paddingLeft': '18px'}}>
        <code className="language-javascript" dangerouslySetInnerHTML={{ __html: code2 }}>
        </code>
        </pre>
    </div>
    <div>
        3. Download the certificate and private key pair for your <Link className="link" to="/app/domains">RedirectTo.Me domain</Link>.
    </div>
    <div>
        4. Configure Express to use your certificate.
    </div>
    <div className="mockup-code text-sm">
        <pre style={{'paddingLeft': '18px'}}>
        <code className="language-javascript" dangerouslySetInnerHTML={{ __html: code3 }}>
        </code>
        </pre>
    </div>
    <div>
        5. Start your HTTPS server
    </div>
    <div className="mockup-code text-sm">
        <pre style={{'paddingLeft': '18px'}}>
        <code className="language-javascript" dangerouslySetInnerHTML={{ __html: code4 }}>
        </code>
        </pre>
    </div>
    <div>
        5. Visit your domain + port combo via <code>https</code> in your browser.
        <br/>
        e.g. <code>https://magic-moment-1234.redirectto.me:8001</code>
    </div>
    </WrapperView>
    </Layout>
)

export default FaqPage

export const Head = ({ location, params, data, pageContext }) => {
    return (
    <>
      <title>How to configure SSL certificate with Express.JS for an HTTPS server</title>
      <meta name="description" content="How to configure SSL certificate with Express.JS for an HTTPS server" />
      <meta name="keywords" content="security, ssl, tls, certificate, localhost, express, expressjs, node, nodejs" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://redirectto.me${location.pathname}`} />
        <meta property="og:title" content="How to configure SSL certificate with Express.JS for an HTTPS server" />
        <meta property="og:description" content="How to configure SSL certificate with Express.JS for an HTTPS server" />

        <meta property="twitter:card" content="summary" />
        <meta property="twitter:url" content={`https://redirectto.me${location.pathname}`} />
        <meta property="twitter:title" content="How to configure SSL certificate with Express.JS for an HTTPS server" />
        <meta property="twitter:description" content="How to configure SSL certificate with Express.JS for an HTTPS server" />
    </>
    )
  }